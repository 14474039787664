import * as React from "react";
import { useMemo } from "react";

import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import logotamhid from "../../../assets/image/logotamhid.png";
import LanguageToggleButton from "../../../shared/toggleLanguage";
import { useTranslation } from "react-i18next";
import Grow from "@mui/material/Grow";

function ResponsiveAppBar() {
  const [anchorElNav, setAnchorElNav] = useState(null);
  const navigate = useNavigate();
  const { i18n, t } = useTranslation();
  const locale = i18n.language;

  const pages = useMemo(() => [
    { label: t("home"), path: "/" },
    { label: t("about_us"), path: "/about" },
    { label: t("our_services"), path: "/services" },
    { label: t("tamheed_space"), path: "/tamhidspace" },
    { label: t("contact_us"), path: "/contactus" },
  ], [t]);

  useEffect(() => {
  }, [pages]); 
  const [activePage, setActivePage] = useState(pages[0]?.label || "");

  useEffect(() => {
    const currentPage = pages.find(
      (page) => window.location.pathname === page.path
    );
    setActivePage(currentPage?.label || "");
  }, [pages]);


  const handleHomeRoute = () => {
    navigate("/");
  };

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handlePageChange = (page) => {
    navigate(page.path);
    setActivePage(page.label);
    handleCloseNavMenu();
  };

  return (
    <AppBar
      position="sticky"
      sx={{
        backgroundColor: "white",
        top: 1,
        zIndex: 1300,
        py: 1,
      }}
      elevation={0}
    >
      <Container maxWidth="xl">
        <Toolbar>
          
        
          
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "flex-start",
              flexDirection: "row-reverse",
              cursor: "pointer",
            }}
            onClick={handleHomeRoute}
          >
            <Box
              component="img"
              src={logotamhid}
              loading="lazy"
              alt="logotamhid"
              sx={{
                paddingLeft: locale === "ar" ? "15px" : "",
                paddingRight: locale === "en" ? "15px" : "",
                width: {
                  xs: "100px",
                  sm: "120px",
                  md: "156px",
                },
                height: "auto",
              }}
            />
          </Box>

          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "none", md: "flex" },
              justifyContent: "flex-end",
              alignItems: "center",
              gap: "15px",
            }}
          >
            {pages.map((page) => (
              <Button
                key={page.label}
                onClick={() => handlePageChange(page)}
                sx={{
                  textTransform: "capitalize",
                  my: 2,
                  color: activePage === page.label ? "#000000" : "#C2C6CC",
                  borderBottom:
                    activePage === page.label ? "1px solid #000000" : "none",
                  display: "block",
                  borderRadius: 0,
                  fontSize: { md: "12px", lg: "16px" },
                  fontWeight: "700",
                }}
              >
                {page.label}
              </Button>
            ))}
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: 0.5,
              }}
            ></Box>

            <LanguageToggleButton />
          </Box>
          <Box
            sx={{
              flexGrow: { xs: 1, sm: 1, md: 0 },
            }}
          ></Box>
          <Box
            sx={{
              display: { sx: "flex", md: "none" },
              justifyContent: "center",
            }}
          >
            <LanguageToggleButton />
          </Box>
          <Box sx={{ display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="#000"
            >
              <MenuIcon />
            </IconButton>
            <Menu
               id="menu-appbar"
               anchorEl={anchorElNav}
               anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
               keepMounted
               transformOrigin={{ vertical: "top", horizontal: "left" }}
               open={Boolean(anchorElNav)}
               onClose={handleCloseNavMenu}
               TransitionComponent={Grow}
              sx={{
                display: { xs: "block", md: "none" },
                "& .MuiMenu-paper": {
                  backgroundColor: "#f0f8ff",
                  borderRadius: "8px",
                  width: "100%",
                },
                "& .MuiMenu-list":{
                  backgroundColor:"white"
                }
              }}
            >
              {pages.map((page) => (
                <MenuItem
                  key={page.label}
                  onClick={() => handlePageChange(page)}
                  sx={{
                    backgroundColor:
                      activePage === page.label ? "#e7eef5" : "white",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "18px",
                      color: "black",
                      fontWeight: "700",
                    }}
                  >
                    {page.label}
                  </Typography>
                </MenuItem>
              ))}
              <Box sx={{ display: "flex", justifyContent: "center" }}></Box>
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}

export default ResponsiveAppBar;
