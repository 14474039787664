import { Box, Grid, Link, Typography } from "@mui/material";
import { Container, useTheme } from "@mui/system";
import { useEffect, useState } from "react";
import Footer2 from "../../../assets/image/footer2.png";
import Phone from "../../../assets/image/phonefooter.png";
import mobile from "../../../assets/image/mobileFooter.png";
import Envelope from "../../../assets/image/envelopefooter.png";
import Locationdot from "../../../assets/image/locationfooter.png";
import Logofooter from "../../../assets/image/logofooter.png";
import Mada from "../../../assets/image/mada.png";
import Visa from "../../../assets/image/visa.png";
import Payment from "../../../assets/image/payment.png";
import ApplePay from "../../../assets/image/ApplePay.png";
import visionlogo from "../../../assets/image/visionlogo.png";
import Saudi from "../../../assets/image/Saudi.png";
import { useDispatch, useSelector } from "react-redux";
import { fetchData } from "../../../redux/slices/HomeSlice";
import { useTranslation } from "react-i18next";
import Wazin from "../../../assets/image/wazin.png";
import WazinEn from "../../../assets/image/Wazinen.png";
import { Offcanvas } from "react-bootstrap";
import CloseIcon from "@mui/icons-material/Close";
export default function ResponsiveFooter() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { data, status, error } = useSelector((state) => state.home);
  const { i18n, t } = useTranslation();
  const currentLang = i18n.language;
  const [drawerContent, setDrawerContent] = useState(null);
  const [show, setShow] = useState(false);

  const [imageSrc, setImageSrc] = useState(Wazin);

  useEffect(() => {
    if (currentLang === "ar") {
      setImageSrc(Wazin);
    } else {
      setImageSrc(WazinEn);
    }
  }, [currentLang]);

  useEffect(() => {
    if (status === "idle") {
      dispatch(fetchData());
    }
  }, [status, dispatch]);

  if (status === "loading") return <div>Loading...</div>;
  if (status === "failed") return <div>Error: {error}</div>;

  const footerData = data?.data?.settings;
  const privacyData = data?.data?.static_pages;

  const handleTitleClick = (content) => {
    setDrawerContent(content);
    setShow(true);
  };

  const handleClose = () => setShow(false);

  return (
    <>
      <Box
        sx={{
          textAlign: "center",
          mt: 10,
          backgroundColor: "#000",
          py: 6,
        }}
      >
        <Container maxWidth={"lg"}>
          <Grid
            container
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "start",
              gap: { xs: 4, sm: 0 },
              flexDirection: {md:"row",lg:"row-reverse"},
            }}
          >
            {/* Company Info */}
            <Grid
              item
              xs={12}
              sm={6}
              // md={3}
              lg={3}
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                mb: 2,
                justifyContent: "space-between",
                height: "185px",
                order: { xs: 4, md: 4,lg:1 },
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "flex-start",
                  justifyContent: "space-between",
                  gap: "5px",
                }}
              >
                <img
                  src={Footer2}
                  loading="lazy"
                  alt="Saudi Business Center Logo"
                  style={{ maxWidth: "150px" }}
                />
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    flexDirection: "column",
                    gap: currentLang === "ar" ? 1 : 1,
                  }}
                >
                  <Typography
                    textAlign={currentLang === "ar" ? "right" : "left"}
                    sx={{ color: "#BCC3C7", fontSize: "14px" }}
                  >
                    {footerData?.company_name[currentLang]}
                  </Typography>
                  <Typography
                    textAlign={currentLang === "ar" ? "right" : "left"}
                    sx={{ color: "#BCC3C7", fontSize: "14px" }}
                  >
                    {t("Tax-Number")}:{footerData?.commercial_register}
                  </Typography>
                  <Typography
                    textAlign={currentLang === "ar" ? "right" : "left"}
                    sx={{ color: "#BCC3C7", fontSize: "14px" }}
                  >
                    {t("Commercial-Registration")}:{footerData?.tax_number}
                  </Typography>
                </Box>
              </Box>
              <img
                src={Saudi}
                loading="lazy"
                alt="Saudi Business Center Logo"
                style={{ maxWidth: "269px", display: "none" }}
              />
            </Grid>

            {/* Contact Information */}
            <Grid
              item
              xs={12}
              sm={6}
              // md={3}
              lg={3}
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                gap: 1.5,
                order: { xs: 3, md: 3 , lg: 3},
              }}
            >
              <Typography
                sx={{
                  fontWeight: "700",
                  fontSize: "20px",
                  color: "#FFFFFF",
                }}
              >
                {t("Contact-Information")}
              </Typography>
              <Link
                href={`tel:${data?.data?.settings?.phone2}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: 1,
                    gap: 1,
                  }}
                >
                  <img
                    src={mobile}
                    loading="lazy"
                    alt="Saudi Business Center Logo"
                    style={{ maxWidth: "25px" }}
                  />
                  <Typography sx={{ color: "#BCC3C7", fontWeight: "400" }}>
                    {footerData?.phone2}
                  </Typography>
                </Box>
              </Link>
              <Link
                href={`tel:${data?.data?.settings?.phone1}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: 1,
                    gap: 1,
                  }}
                >
                  <img
                    src={Phone}
                    loading="lazy"
                    alt="Saudi Business Center Logo"
                    style={{ maxWidth: "150px" }}
                  />
                  <Typography sx={{ color: "#BCC3C7", fontWeight: "400" }}>
                    {footerData?.phone1}
                  </Typography>
                </Box>
              </Link>
              <Link
                href={`mailto:${data?.data?.settings?.email}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: 1,
                    gap: 1,
                  }}
                >
                  <img
                    src={Envelope}
                    loading="lazy"
                    alt="Saudi Business Center Logo"
                    style={{ maxWidth: "150px" }}
                  />
                  <Typography sx={{ color: "#BCC3C7", fontWeight: "400" }}>
                    {footerData?.email}
                  </Typography>
                </Box>
              </Link>
              <Link
                href={`https://www.google.com/maps?q=:${data?.data?.settings?.email}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "flex-start",
                    marginTop: 1,
                    gap: 1,
                  }}
                >
                  <img
                    src={Locationdot}
                    loading="lazy"
                    alt="Saudi Business Center Logo"
                    style={{ maxWidth: "150px" }}
                  />
                  <Typography
                    sx={{
                      color: "#BCC3C7",
                      fontWeight: "400",
                      textAlign: currentLang === "ar" ? "right" : "left",
                    }}
                  >
                    {footerData?.location[currentLang]}
                  </Typography>
                </Box>
              </Link>
            </Grid>

            {/* Other Links */}
            <Grid
              item
              xs={12}
              sm={6}
              // md={2}
              lg={3}
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                gap: 1.5,
                order: { xs: 2, md: 2, lg: 2 },
              }}
            >
              <Typography
                sx={{
                  fontWeight: "700",
                  fontSize: "20px",
                  color: "#FFFFFF",
                }}
              >
                {t("Other-Resources")}
              </Typography>
              {privacyData?.map((item, index) => (
                <Typography
                  key={index}
                  sx={{
                    color: "#BCC3C7",
                    fontWeight: "400",
                    cursor: "pointer",
                  }}
                  onClick={() => handleTitleClick(item.content[currentLang])}
                >
                  {item.title[currentLang]}
                </Typography>
              ))}
            </Grid>

            {/* Company Description */}
            <Grid
              item
              xs={12}
              sm={6}
              // md={4}
              lg={3}
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                gap: 1,
                order: { xs: 1, md: 1 , lg: 4},
              }}
            >
              <Box sx={{ display: "flex", gap: 1 }}>
                <img
                  src={visionlogo}
                  loading="lazy"
                  alt="Saudi Business Center Logo"
                  style={{ maxWidth: "150px", marginLeft: "2px" }}
                />
                <img
                  src={Logofooter}
                  loading="lazy"
                  alt="Saudi Business Center Logo"
                  style={{
                    maxWidth: "150px",
                    borderRight: currentLang === "ar" ? "1px solid white" : "",
                    borderLeft: currentLang === "en" ? "1px solid white" : "",
                    paddingRight: currentLang === "ar" ? "5px" : "",
                    paddingLeft: currentLang === "en" ? "5px" : "",
                  }}
                />
              </Box>

              <Typography
                sx={{
                  color: "#FFFFFF",
                  fontWeight: "400",
                  width: "90%",
                  textAlign: currentLang === "ar" ? "right" : "left",
                }}
              >
                {t("We-help")}
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </Box>

      {/* Footer Bottom */}
      <Container maxWidth={"lg"}>
        <Grid
          container
          sx={{
            borderTop: "1px solid",
            borderImage:
              "linear-gradient(to right, rgba(187, 215, 252, 0) 10%, #BBD7FC33 20%, #07489D66 40%, #BBD7FC33 80%, rgba(187, 215, 252, 0) 60%) 1",
            pt: 4,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            flexDirection: { xs: "column", sm: "row" },
            gap: { xs: 5, sm: 0 },
            py: 3,
          }}
        >
          <Typography
            sx={{
              marginTop: "10px",
              color: theme.palette.primary.title,
              fontWeight: "400",
              fontSize: "16px",
            }}
          >
            {t("All-rights")}
          </Typography>
          <Box
            sx={{ display: "flex", justifyContent: "space-between", gap: 4 }}
          >
            <img
              src={Mada}
              loading="lazy"
              alt="Saudi Business Center Logo"
              style={{ maxWidth: "150px" }}
            />
            <img
              loading="lazy"
              src={Visa}
              alt="Saudi Business Center Logo"
              style={{ maxWidth: "150px" }}
            />
            <img
              src={Payment}
              loading="lazy"
              alt="Saudi Business Center Logo"
              style={{ maxWidth: "150px" }}
            />
            <img
              src={ApplePay}
              loading="lazy"
              alt="Saudi Business Center Logo"
              style={{ maxWidth: "150px" }}
            />
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              gap: 1,
              alignItems: "center",
            }}
            component="a"
            href="https://www.wazin.sa/"
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: "none" }}
          >
            <Typography
              sx={{
                color: theme.palette.primary.title,
                fontWeight: "400",
                fontSize: "16px",
              }}
            >
              {t("made")}
            </Typography>
            <img
              src={imageSrc}
              loading="lazy"
              alt="Saudi Business Center Logo"
              style={{ maxWidth: "60px" }}
            />
          </Box>
        </Grid>
      </Container>
      <Offcanvas
        show={show}
        onHide={handleClose}
        placement={currentLang === "ar" ? "end" : "start"}
        className="custom-offcanvas"
      >
        <Offcanvas.Header className="custom-header">
          <Offcanvas.Title className="custom-title">
            {
              privacyData?.find(
                (item) => item.content[currentLang] === drawerContent
              )?.title[currentLang]
            }
          </Offcanvas.Title>
          <CloseIcon className="custom-close-icon" onClick={handleClose} />
        </Offcanvas.Header>
        <Offcanvas.Body className="custom-body">
          <div dangerouslySetInnerHTML={{ __html: drawerContent }} />
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}
